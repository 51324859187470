import 'devextreme/dist/css/dx.material.blue.light.compact.css';

import './assets/css/base.css';
import './assets/css/colors.css';
import './styles/main.css';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
// import hub from './hub'
import i18nMessages from './i18n';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAnglesLeft,
  faAnglesRight, faBars, faInfo, faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { appFrontendVersion, appName, isProduction } from './env';

library.add(faBars, faAnglesRight, faAnglesLeft, faXmark, faInfo);

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueI18n)
Vue.config.productionTip = false

const messages = i18nMessages;
let lcl = localStorage.getItem("locale") || navigator.language || navigator.userLanguage;
const i18n = new VueI18n({
  locale: lcl,
  silentTranslationWarn: true,
  fallbackLocale: 'en',
  messages
});

new Vue({
  router,
  i18n,
  store,
  created() {
    if(!isProduction)
    {
      console.log("--------------------")
      console.log("App name : ", appName);
      console.log("Is pruduction version : ", isProduction);
      console.log("Version : ", appFrontendVersion);
      console.log("--------------------")
    }
  },
  render: h => h(App)
}).$mount('#app')
