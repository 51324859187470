import NotFound from '@/pages/not-found'
import Vue from 'vue'
import VueRouter from 'vue-router'
import authService from '../services/auth.service'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/dashboard'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/About",
    name: "About",
    component: () => import("@/pages/about")
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login'),
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import("@/pages/register"),
  },
  {
    path: '/devices',
    name: 'DeviceList',
    component: () => import("@/pages/devices"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/users',
    name: 'user-list',
    component: () => import("@/pages/users/users-list.vue"),
    meta: {
      requiresAuth: true,
      role: 'admin',
    }
  },
  {
    path: '/groups',
    name: 'groups-list',
    component: () => import("@/pages/groups"),
    meta: {
      requiresAuth: true,
      role: 'admin',
    }
  },
  {
    path: '/logs',
    name: 'LogsList',
    component: () => import("@/pages/logs"),
    meta: {
      requiresAuth: true,
      role: 'admin',
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import("@/pages/profile"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: () => import("@/pages/change-password"),
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: "/*",
    name: "404",
    component: NotFound,
    meta: {
      requiresAuth: true,
    }
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isLogined = authService.isLogined();
  const userRole = authService.userRole();

  const needAuth = to.meta.requiresAuth;
  const role = to.meta.role;

  //нужно ваторизоваться и пользователь не авторизован
  if (needAuth && !isLogined) {
    next({
      path: '/login',
      params: { nextUrl: to.fullPath }
    })
  }
  //пользователь авторизован или авторизация не требуется, но требуется роль и роль не совпадает
  else if (role && (role !== userRole)) {
    next({
      name: '404'
    })
  }
  //все ОК
  else {
    next()
  }
  next();

});

export default router
