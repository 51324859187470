import Vue from 'vue'
import Vuex from 'vuex'
import groups from './modules/groups'
import notificationsModule from './modules/notification'
import session from './modules/session'
import users from './modules/users'
import settings from './modules/settings'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    notificationsModule,
    session,
    settings,
    users,
    groups
  }
})

export default store;