export default {
    validationErrors: {
        invalidEmail: "Invalid email",
        invalidUsername: "Invalid username",
        invalidPassword: "Invalid password",

    },
    deviceTags: {
        Balance: "Balance(sim1)",
        Balance2: "Balance(sim2)",
        BER: "Ber",
        Crypto: "Crypto",
        GeoAlt: "Altitude",
        GeoLat: "Latitude",
        GeoLong: "Longitude",
        ICCID: "ICCID(sim1)",
        ICCID2: "ICCID(sim2)",
        ID: "Id",
        IMEI: "IMEI",
        MAC: "MAC-address",
        ModType: "Module type",
        NetMode: "Net mode",
        Operator: "Operator (sim1)",
        Operator2: "Operator (sim2)",
        ProtVer: "Protocol version",
        RSSI: "RSSI",
        SimState: "Sim state(sim1)",
        SimState2: "Sim state(sim2)",
        SN: "Serial number",
        Type: "Type",
        Ver: "Version",
    },
    
    role: 'Role',
    userName: 'User name',
    userInfoPageHeader: 'User',
    groups: 'Groups',
    pageNotFoundText:"Page not found",
    dashboardPageHeader: 'Actual information',
    registredLbl: 'Registred', 
    totalConnectedLbl: 'Total connected',
    connectedDevicesLbl:"Devices connected",
    onlineLbl: 'Online',
    busyLbl: 'Busy',
    idleLbl: 'Idle',
    devicePortLbl: 'TCP-port for devices',
    amrPortLbl: 'TCP-port for AMR',
    about: 'About',
    noDataText:"No data",
    changePasswordLbl: "Change password",
    dashboardLbl: 'Dashboard',
    devicesLbl: 'Devices list',
    importsLbl: 'Import devices',
    exportsLbl: 'Export devices',
    aboutLbl: 'About',
    usersLbl: 'Users list',
    groupsLbl: 'Groups list',
    logsLbl: 'Debug log',
    settingsLbl: 'Server settings',
    profile:"Profile",
    language:"Language",
    scheme:"Color scheme",
    settings:"Settings",
    changePasswordHeader:"Change password",
    registerGreetings:"Don't have an account yet?",
    registerLink:"Register!",
    save:"Save",
    default:"Default",
    light:"Light",
    dark:"Dark",
    russian:"Russian",
    english:"English",
    loginFail: "Login fail",
    detail: "Detail",
    registarionFaild: "Failed to register user. Maybe the name or email is already taken",
    submitAction: "Submit action",
    submitDeleteText: "Are you sure you want to delete selected devices?",
    submitPasswordChangeText:"Are you sure you want to change your password?",
    submitDeleteButtonText: "Submit",
    submit:"Submit",
    cancel: "Cancel",
    createAccount: "Create account",
    createAccountAdmin: "Create account for user",
    login: "Login",
    logout: "Logout",
    email: "Email",
    password: "Password",
    passwordNew: "New password",
    passwordRepeat:"Repeat password",
    refresh: "Refresh",
    deletingDevices:"Deleting devices",
    delete: "Remove selected",
    journalType: "Journal type",
    levelType: "Message type",
    dateTime: "Date-time",
    message: "Message",
    journalNames: {
        JRNL_START: "Server start",
        JRNL_CONFIG: "Configuration changes",
        JRNL_ERRORS: "Errors",
        JRNL_AMR_LOG: "Amr",
        JRNL_DEV_LOG: "Devices",
    },
    logLevels: {
        TRACE: "Trace",
        IN: "In",
        OUT: "Out",
        CON: "Connections",
        DIS: "Disconnections",
        DEBUG: "Debug",
        INFO: "Information",
        WARN: "Warnings",
        ERROR: "Errors",
        FATAL: "Fatal errors",
    },

    serialNumber: "Serial number",
    deviceConnectionStatus: "Connection status",
    typeName: "Type",
    
    lastConnectionDateTime:"Last connection date time",
    registrationDateTime: "Registration date time",
    systemAddress: 'System address',
    directAccessPort: "Direct access port",
    protocolVersion: "Protocol version",
    ipAddress: "IP address",
    deviceUserName: "User naming",
    userDescription: "User description",

    deviceInfo: "Common",
    deviceGroups: "Groups",
    deviceTagsList:"Tags",

    connectionStatuses: {
        NULL: "-",
        Offline: "Offline",
        Online: "Online",
        Idle: "Idle",
        Busy: "Busy",
    }
}