import groupApi from '@/services/groups.service';

const state = {
    groups: [],
};

const mutations = {
    SET_GROUPS(state, groups) {
        state.groups = groups;
    },
    ADD_GROUP(state, group) {
        state.groups.push(group);
    },
    DELETE_GROUP(state, groupId) {
        state.groups = state.groups.filter(x => x.id != groupId);
    }

};

const actions = {
    loadGroups: async ({ commit }) => {
        const result = await groupApi.loadGroups()
        commit("SET_GROUPS", result)
    },
    addGroup: async ({ commit }, groupName) => {
        const result = await groupApi.createGroup(groupName);
        if (result) {
            commit("ADD_GROUP", result);
        }
    },
    deleteGroup: async ({ commit }, groupId) => {
        const deleted = await groupApi.deleteGroup(groupId);
        if (deleted) {
            commit("DELETE_GROUP", groupId);
        }
    },
};

const getters = {
    groups: (state) => state.groups,
};


const module = {
    state,
    mutations,
    actions,
    getters
}

export default module;