import store from '@/store';
import axios from "axios";
import { apiUri } from '../env';

export const httpSettings = {
    baseURL: apiUri
}

const http = axios.create(httpSettings);

//настройка перехватчика для axios
function setup() {
    //для запросов
    http.interceptors.request.use(function (config) {
        //если пользователь залогинился - крепим токен
        if (store.getters.isLogin) {
            const token = store.getters.token;
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, function (err) {
        return Promise.reject(err);
    });
    
    //для ответов
    http.interceptors.response.use(
        //коллбек при респонсе с нормальным ответом(2xx)
        function (response) {
            return response;
        },
        //коллбек для любых отличных от 2xx статус-кодов
        function (error) {
            console.error(error);
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                localStorage.removeItem("username");
                document.location.replace('/');
            }
            //store.dispatch("addNotification", { type: "warn", message: error })
            return Promise.reject(error);
        }
    )
}

setup();

export default http;