export default  {
    validationErrors: {
        invalidEmail: "Неверный email",
        invalidUsername: "Неверное имя пользователя",
        invalidPassword: "Неверный пароль",

    },
    deviceTags: {
        Balance: "Баланс(сим1)",
        Balance2: "Баланс(сим2)",
        BER: "Коэффициент битовых ошибок",
        Crypto: "Код алгоритма шифрования",
        GeoAlt: "Высота над уровнем моря",
        GeoLat: "Широта",
        GeoLong: "Долгота",
        ICCID: "Серийный номер сим1",
        ICCID2: "Серийный номер сим2",
        ID: "ID устройства",
        IMEI: "IMEI",
        MAC: "MAC-адрес",
        ModType: "Тип модуля",
        NetMode: "Режим работы сети",
        Operator: "Оператор(сим1)",
        Operator2: "Оператор(сим2)",
        ProtVer: "Версия протокола",
        RSSI: "Качество сигнала GSM",
        SimState: "Состояние сим1",
        SimState2: "Состояние сим2",
        SN: "Серийный номер",
        Type: "Тип устройства",
        Ver: "Версия ПО",
    },
    
    role: 'Роль',
    userName: 'Логин',
    userInfoPageHeader: 'Пользователь',
    groups: 'Группы',
    pageNotFoundText:"Страница не найдена",
    dashboardPageHeader: 'Информация о работе',
    registredLbl: 'Зарегистрировано',
    totalConnectedLbl: 'Всего подключений',
    connectedDevicesLbl:"Подключено устройств",
    onlineLbl: 'На связи',
    busyLbl: 'Занято',
    idleLbl: 'Простаивает',
    devicePortLbl: 'TCP-порт для устройств',
    amrPortLbl: 'TCP-порт для ПО верхнего уровня',    
    about: 'О программе',
    noDataText:"Нет данных",
    changePasswordLbl: "Изменить пароль",
    dashboardLbl: 'Общая информация',
    devicesLbl: 'Список устройств',
    importsLbl: 'Импорт устройств',
    exportsLbl: 'Экспорт устройств',
    aboutLbl: 'О программе',
    usersLbl: 'Список пользователей',
    groupsLbl: 'Список групп',
    logsLbl: 'Отладочный журнал',
    settingsLbl: 'Настройки сервера',    
    profile:"Профиль",
    language:"Язык",
    scheme:"Цветовая схемa",
    settings:"Настройки",
    changePasswordHeader:"Смена пароля",
    registerGreetings:"У вас нет учетной записи?",
    registerLink:"Зарегистрируйтесь!",
    save:"Сохранить",
    default:"По умолчанию",
    light:"Светлая",
    dark:"Темная",
    russian:"Русский",
    english:"Английский",
    loginFail: "Не удалось войти",
    detail: "Подробнее",
    registarionFaild: "Не удалось зарегистрировать пользователя. Возможно имя или электронная почта уже заняты",
    submitAction: "Подтвердите действие",
    submitDeleteText: "Вы уверены что хотите удалить выбранные устройства?",
    submitDeleteButtonText: "Подтвердить",
    submitPasswordChangeText:"Вы уверены, что хотите изменить свой пароль?",
    submit:"Подтвердить",
    cancel: "Отмена",
    createAccount: "Зарегистрироваться",
    createAccountAdmin: "Зарегистрировать пользователя",
    login: "Войти",
    logout: "Выйти",
    email: "Электронная почта",
    password: "Пароль",
    passwordNew: "Новый пароль",
    passwordRepeat:"Повторите пароль",

    refresh: "Обновить",
    deletingDevices:"Удаление устройств",
    delete: "Удалить выбранные",
    journalType: "Тип журнала",
    levelType: "Тип сообщения",
    dateTime: "Дата-время",
    message: "Сообщение",
    journalNames: {
        JRNL_START: "Запуск сервера",
        JRNL_CONFIG: "Изменение конфигурации",
        JRNL_ERRORS: "Ошибки",
        JRNL_AMR_LOG: "ПО верхнего уровня",
        JRNL_DEV_LOG: "Устройства",
    },
    logLevels: {
        TRACE: "Трассировка",
        IN: "Входящий трафик",
        OUT: "Исходящий трафик",
        CON: "Подключения",
        DIS: "Отключения",
        DEBUG: "Отладка",
        INFO: "Информация",
        WARN: "Предупреждения",
        ERROR: "Ошибки",
        FATAL: "Критические ошибки",
    },
    serialNumber: "Серийный номер",
    deviceConnectionStatus: "Статус соединения",
    typeName: "Тип",
    lastConnectionDateTime:"Дата-время последнего подключения",
    registrationDateTime: "Дата-время регистрации",
    systemAddress: 'Системный адрес',
    directAccessPort: "Порт прямого доступа",
    protocolVersion: "Версия протокола",
    ipAddress: "IP адрес",
    deviceUserName: "Пользовательское название",
    userDescription: "Пользовательское описание",

    deviceInfo: "Основная информация",
    deviceGroups: "Группы",
    deviceTagsList:"Тэги",
    connectionStatuses: {
        NULL: "-",
        Offline: "Отключен",
        Online: "Подключен",
        Idle: "Простаивает",
        Busy: "Занят",
    }
};