export const appName = "CENCn";

const isDebugMode = process.env.VUE_APP_IS_DEBUG;
export const apiUri = process.env.VUE_APP_API_URI

//основная версия фронтенда. Тут добавляются фичи и расширяется функционал
export const appFrontendMajorVersion = "1.0.0.0"

//внутренняя версия фронтенда. При инкрементации основной версии - сбрасывается
export const appFrontendMinorVersion = "beta 0004"

//версия фронтенда которую можно показывать пользователю
export const appFrontendVersion = isDebugMode ? `${appFrontendMajorVersion} ${appFrontendMinorVersion}` : appFrontendMajorVersion
