
function createElement(path, text, icon){
    return {
        path:path,
        text:text,
        icon:icon,
    }
}

function createGroup(text, items, showGroupPredicate){
    return {
        show: showGroupPredicate,
        text: text, 
        items: items
    }
}

export { createElement, createGroup };
