<script>
import {
    createElement,
    createGroup
} from './layouts/side-navigation/side-navigation.util.js';

import { loadMessages } from 'devextreme/localization';
import ruMessages from 'devextreme/localization/messages/ru.json';

export default {
    components: {
        topBar: () => import('./layouts/header/index.vue'),
        SideNavigation: () =>
            import('./layouts/side-navigation/index.vue'),
        CeLayout: () => import('./components/atoms/ce-layout.vue'),
    },
   
    created() {
        this.$store.dispatch("initSettings");
        this.$store.dispatch('initSession');
        this.initGlobalize();
    },
    methods: {
        initGlobalize() {
            this.$root.$i18n.locale = this.$store.getters.language
            loadMessages(ruMessages);
        },

        getSideNavFromLocalStorage() {
            const data = localStorage.getItem("showSideNav");
            return data === "true";
        },
        saveSideNavToLocalStorage(val) {
            localStorage.setItem("showSideNav", `${val}`);
        },
        onHideButtonClicked() {
            this.showSideNav = !this.showSideNav;
            this.saveSideNavToLocalStorage(this.showSideNav);
        }
    },
    computed: {
        isLogin() {
            return this.$store.getters.isLogin;
        },
        today() {
            return new Date().getFullYear();
        },

        scheme() {
            return this.$store.getters.scheme
        }
    },
    data() {
        const commonItems = [
            createElement('/', () => this.$t('dashboardLbl'), 'Dashboard'),
            createElement('/Devices', () => this.$t('devicesLbl'), 'Devices'),
            // createElement("/Imports", this.$t('importsLbl'), "Imports"),
            // createElement("/Exports", this.$t('exportsLbl'), "Exports"),
            createElement('/About', () => this.$t('aboutLbl'), 'About'),
        ];
        const commonGroup = createGroup('Common', commonItems, () => true);

        const adminItems = [
            createElement('/Users', () => this.$t('usersLbl'), 'Users'),
            createElement('/Groups', () => this.$t('groupsLbl'), 'Groups'),
            createElement('/Logs', () => this.$t('logsLbl'), 'Logs'),
            // createElement("/Settings",this.$t('settingsLbl'), "Settings"),
        ];
        const adminGroup = createGroup('Admin', adminItems, () => {
            return this.$store.getters.userRole === 'admin';
        });
        const prevSideNavValue = localStorage.getItem("showSideNav");
        if (!prevSideNavValue) {
            localStorage.setItem("showSideNav", "false");
        }
        this.saveSideNavToLocalStorage(this.getSideNavFromLocalStorage());
        return {
            showPopup: true,
            showSideNav: this.getSideNavFromLocalStorage(),
            navbarGroups: [commonGroup, adminGroup],
        };
    },
};
</script>

<template>
    <div id="app">
        <top-bar v-show="isLogin" @hideButtonClicked="onHideButtonClicked" class="dark-primary-color" applicationTitle="CENCn" />
        <div class="main-app-wrapper bg-main">
            <side-navigation class="border-right" v-show="isLogin" :groups="navbarGroups" v-model="showSideNav" />
            <ce-layout>
                <router-view />
            </ce-layout>
        </div>
    </div>
</template>

<style>
@import './assets/css/base.css';
@import './assets/css/colors.css';
@import './styles/main.css';

.on-top {
    z-index: 99;
}

.m-l-10 {
    margin-left: 10px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-10 {
    margin: 10px;
}

.border-right {
    border-right: 1px solid rgb(215 215 215);
}

#app {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-flow: column;
    height: 100vh;
    font-weight: 100;
}

.footer {
    /* height: 45px; */
    /* margin-top: 15px; */
    padding-left: 25px;
}

.main-app-wrapper {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
}

@media screen and (max-width: 850px) {
    .main-app-wrapper {
        display: flex;
        /* flex-direction: column; */
        overflow: hidden;
        height: 100%;
    }
}
</style>
