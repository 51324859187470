import http from '../config/http.config'

const groupApiPath = "api/Group";
export default {
    loadGroups: async () => {
        const result = [];
        try {
            const response = await http.get(groupApiPath);
            if (response.status === 200) {
                result.push(...response.data);
            }
        } catch (e) { console.error(e); }
        return result;
    },
    createGroup: async (groupName) => {
        try {
            const responce = await http.post(groupApiPath, { name: groupName });
            if (responce.status === 200) {
                return responce.data;
            }
        } catch (e) { console.error(e) }
        return null;
    },
    deleteGroup: async (groupId) => {
        try {
            const responce = await http.delete(`${groupApiPath}/${groupId}`)
            if (responce.status === 200) {
                return true;
            }
        } catch (e) { console.error(e) }
        return false;
    }

}