
// { "id" : "uuid", "type" : "message|warn|error|success"; "message" : "Some useful notification text" }

import { uuid } from 'vue-uuid';
const state = {
    notifications: []
};

const mutations = {
    ADD_NOTIFICATION(state, notification) {
        state.notifications.push(notification);
    },

    REMOVE_NOTIFICATION(state, id) {
        state.notifications = state.notifications.filter(x => x.id != id);
    }
};

const actions = {
    addNotification({ commit }, notification) {
        const id = uuid.v1();
        notification.id = id;
        notification.added = new Date();
        commit("ADD_NOTIFICATION", notification);
    },

    removeNotification({ commit }, id) {
        commit("REMOVE_NOTIFICATION", id);
    }
};

const getters = {
    getNotification: (state) => state.notifications,
};


const module = {
    state,
    mutations,
    actions,
    getters
}

export default module;