import http from "../config/http.config"

const registerApiPath = "api/Identification"
const userInfoApiPath = "api/UserInfo"
async function getUsers() {
    const result = await http.get(userInfoApiPath);
    if (result.status === 200) {
        return result.data;
    }
    return []
}

async function getUserById(userId) {
    const responce = await http.get(`${userInfoApiPath}/${userId}`);
    if (responce.status === 200) {
        return responce.data
    }
    return null;
}

async function updateUser(userData) {
    const responce = await http.put(`${userInfoApiPath}`, userData);
    if (responce.status === 200) {
        return userData
    }
    return null;
}

async function deleteUserById(userId) {
    try {

        const result = await http.delete(`${userInfoApiPath}/${userId}`);
        if (result.status === 200 || result.status === 204) {
            return true;
        }
    } catch (e) { console.error(e); }
    return false;

}
async function createUser(userDescription) {
    await http.post(`${registerApiPath}/register`, userDescription);
}

const api = {
    getUsers,
    getUserById,
    updateUser,
    deleteUserById,
    createUser
}

export default api;