
//нужно чтобы всякие devextreme штуки локализовались правильно
import { locale as devextremeLocale } from 'devextreme/localization';


const state = {
    scheme: "default",
    language : "ru",
}

const mutations = {
    SET_SCHEME(state, scheme){
        state.scheme = scheme;
        localStorage.setItem("userScheme", state.scheme);
        document.documentElement.className=state.scheme
    },
    SET_LANGUAGE(state, language){
        state.language = language;
        localStorage.setItem("userLanguage", state.language);
        devextremeLocale(language);
    },
}

const actions = {
    initSettings({ commit }){
        let scheme = localStorage.getItem('userScheme');
        if(!scheme){
            scheme = "default"
        }
        commit("SET_SCHEME", scheme);

        let userLanguage = localStorage.getItem("userLanguage");
        if(!userLanguage){
            userLanguage = navigator.language.split('-')[0].toLowerCase();
        }
        commit("SET_LANGUAGE", userLanguage);
    },
    setScheme({commit}, scheme){
        commit("SET_SCHEME", scheme);
    },
    
    setLanguage({commit}, language){
        commit("SET_LANGUAGE", language);
    },
}
const getters = {
    scheme:(state)=>state.scheme,
    language:(state) => state.language,
};

const module = {
    state,
    mutations,
    actions,
    getters
}

export default module;