<template>
  <div class="not-found">
    <div class="n-message">
      {{$t('pageNotFoundText')}}
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

.not-found{
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  font-size: 2rem;
  position: static;
  overflow: hidden;
}

.not-found .n-message{
  position: relative;
  top: 50%;
  text-align: center;
  user-select: none;
}

</style>