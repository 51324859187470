
import auth from "@/services/auth.service.js";

const state = {
    userName: "",
    userRole: "",
    isLogin: false,
    token: ""
};

const mutations = {
    SET_SESSION_INFO(state, { userName, userRole, isLogin, token }) {
        state.userName = userName;
        state.userRole = userRole;
        state.isLogin = isLogin;
        state.token = token;
    },
    CLEAR_SESSION_INFO(state) {
        state.userName = "",
        state.userRole = "",
        state.isLogin = false
        state.token = "";
    },
};

const actions = {
    async initSession({ commit, dispatch }) {
        if (auth.isLogined()) {
            commit("SET_SESSION_INFO", {
                userName: auth.userName(),
                userRole: auth.userRole(),
                isLogin: true,
                token: auth.userToken()
            });
            if (auth.userRole() === 'admin') {
                await dispatch('loadGroups')
            }
        }
        //чистим все
        else {
            commit("CLEAR_SESSION_INFO");
            commit("SET_GROUPS", [])
        }
    },
    async login({ commit, dispatch }, { email, password }) {
        auth.logout();
        commit("CLEAR_SESSION_INFO");
        const result = await auth.login(email, password);
        if (result) {
            dispatch("initSession");
            return true;
        }
        return false;
    },
    logout({ dispatch }) {
        auth.logout();
        window.location.href = '/';
        dispatch("initSession");
    }
};

const getters = {
    isLogin: (state) => state.isLogin,
    userName: (state) => state.userName,
    token: (state) => state.token,
    userRole: (state) => state.userRole,
};


const module = {
    state,
    mutations,
    actions,
    getters
}

export default module;