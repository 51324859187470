import http from "../config/http.config"

const userNameKey = "userName"
const roleKey = "role"
const tokenKey = "token"

// function parseJwt(token) {
//     var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''))

//     return JSON.parse(jsonPayload)
// }



function saveResult(userInfo) {

    localStorage.setItem(userNameKey, userInfo.userName);
    localStorage.setItem(roleKey, userInfo.role.name);
    localStorage.setItem(tokenKey, userInfo.token);
}

function getToken() {
    const token = localStorage.getItem(tokenKey);
    return token;
}

function isLogined() {
    return !!getToken()
}
function getRole() {
    return localStorage.getItem(roleKey);
}

function clearUserData() {
    if (localStorage.getItem(userNameKey)!==null) {
        localStorage.removeItem(userNameKey)
    }
    if (localStorage.getItem(roleKey)!==null) {
        localStorage.removeItem(roleKey)
    }
    if (localStorage.getItem(tokenKey)!==null) {
        localStorage.removeItem(tokenKey)
    }
}


const auth = {
    async login(email, password) {
        clearUserData();
        const body = { email: email, password: password };
        try {
            const result = await http.post("api/Identification/login", body);
            if (result.status === 200) {
                saveResult(result.data);
                return true;
            }
        } catch (e) { console.error(e); }
        return false;
    },
    async changePassword(newPassword){
        try{
            const result = await http.post("api/Identification/changePassword", {password:newPassword});
            if(result.status === 204){
                this.logout();
                window.location.href="/";
                return true;
            }
        }catch(e){
            console.error(e)
        }
        return false;
    },
    userName: () => localStorage.getItem(userNameKey),
    userRole: () => localStorage.getItem(roleKey),
    userToken: () => localStorage.getItem(tokenKey),
    logout: () => {
        clearUserData();
        //window.location.href="/";
    },
    isLogined: () => isLogined(),
    authToken: () => getToken(),
    IsAdmin: () => getRole() === 'admin'
}

export default auth;