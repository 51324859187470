
import userService from '@/services/users.service';
const state = {
    users: []
};

const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    },
    DELETE_USER_BY_ID(state, userId) {
        state.users = state.users.filter(x => x.id != userId)
    },
    UPDATE_USER(state, user) {
        state.users = state.users.filter(x => x.id != user.id);
        state.users.push(user);
    }
};

const actions = {
    async getUsers({ commit }) {
        const result = await userService.getUsers();
        commit("SET_USERS", result);
    },
    async deleteUserById({ commit }, userId) {
        const result = await userService.deleteUserById(userId);
        if (result) {
            commit("DELETE_USER_BY_ID", userId);
        }
    },
    async updateUser({ commit }, newUserInfo) {
        const res = await userService.updateUser(newUserInfo);
        if (res) {
            commit("UPDATE_USER", newUserInfo);
        }
    }
};

const getters = {
    users: (state) => state.users
};


const module = {
    state,
    mutations,
    actions,
    getters
}

export default module;