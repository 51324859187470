import en from './en-EU';
import ru from './ru-RU';
const messages = {
    ru,
    en
}

import { defineComponent} from 'vue';
export default messages;
export const i18nMix = defineComponent({
    i18n:{
        messages : {...messages }
    }
});